@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .table-body-row {
    @apply bg-white;
  }

  .table-body-row.selected {
    @apply bg-primary-light;
  }

  .btn-primary {
    @apply bg-primary-light text-white hover:text-primary-lightest hover:bg-primary-main cursor-pointer rounded-md p-2;
  }

  .btn-primary-dark {
    @apply bg-primary-dark text-primary-lightest hover:bg-primary-main cursor-pointer rounded-md p-2;
  }

  .btn-primary-light {
    @apply bg-white text-primary-darkest border-primary-main border hover:bg-primary-lightest cursor-pointer rounded-md p-2;
  }
  .btn-danger {
    @apply bg-danger-light text-danger-dark hover:text-danger-light hover:bg-danger-dark cursor-pointer rounded-md p-2;
  }
}

@layer utilities {
  .active-navlink {
    color: #3b3b3b !important;
    /* Work around for Important styling */
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
