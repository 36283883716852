* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Montserrat", sans-serif !important;
}

input[type="text"],
textarea,
button {
  outline: none;
  box-shadow: none !important;
}
